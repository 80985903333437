import React from 'react';
import { PATHS } from 'constants/paths';
import { treatmentsHubs } from 'constants/treatment-hubs';

import { BannerMonthlyPromo } from '../../offer/banner-monthly-promo';
import { TreatmentsList } from '../components/treatments-list';

export const useHyaluronicAcidContent = () => {
  return {
    title: 'Zabiegi z wykorzystaniem kwasu hialuronowego',
    sections: [
      {
        type: 'section',
        subtitle: 'Czym jest kwas hialuronowy?',
        nodes: [
          {
            type: 'paragraph',
            content:
              'Kwas hialuronowy to substancja naturalnie występująca w ludzkim ciele, znana przede wszystkim z jej zdolności do wiązania wody, co zapewnia nawilżenie i jędrność skóry. Jest on szeroko stosowany w medycynie estetycznej do poprawy kondycji skóry twarzy, redukcji zmarszczek oraz modelowania i nawilżania twarzy. Jako składnik kosmetyków nawilżających, <b>kwas hialuronowy na twarz</b> pomaga w utrzymaniu odpowiedniego nawilżenia skóry, co jest kluczowe dla zachowania młodego wyglądu.',
          },
          {
            type: 'paragraph',
            content:
              'Dodatkowo, kwas hialuronowy jest ceniony za swoje właściwości przeciwzapalne i zdolność do przyspieszania procesów gojenia skóry. To sprawia, że jest idealnym wyborem nie tylko do kosmetycznego poprawiania wyglądu skóry, ale także w terapiach regeneracyjnych i leczeniu drobnych uszkodzeń skórnych. Dzięki tym właściwościom, zabiegi z użyciem kwasu hialuronowego są niezwykle popularne wśród osób poszukujących efektywnych metod na odmłodzenie i poprawę stanu skóry, zapewniając długotrwałe i zadowalające rezultaty.',
          },
        ],
      },
      {
        type: 'section',
        subtitle: 'Jak działa kwas hialuronowy?',
        nodes: [
          {
            type: 'paragraph',
            content:
              'Kwas hialuronowy działa poprzez intensywne nawilżanie skóry, co przyczynia się do zwiększenia jej objętości i elastyczności. W medycynie estetycznej, kwas ten jest wykorzystywany w postaci preparatu do iniekcji, który wypełnia zmarszczki, modeluje kontury twarzy i dodaje objętości ustom. Dzięki swoim właściwościom, kwas hialuronowy nie tylko poprawia wygląd zewnętrzny, ale także wspomaga regenerację komórek skóry, co prowadzi do <b>długotrwałej poprawy jej kondycji</b>.',
          },
          {
            type: 'paragraph',
            content:
              'Kwas hialuronowy działa na kilka sposobów, nie tylko nawilżając skórę, ale również pomagając zachować jej naturalną barierę ochronną. <b>Stymulacja produkcji kolagenu</b>, wynikająca z zabiegów z wykorzystaniem tego składnika, dodatkowo wzmacnia strukturę skóry, co przekłada się na jej większą elastyczność i odporność na czynniki zewnętrzne. Wskazane zastosowanie kwasu hialuronowego prowadzi do długotrwałej poprawy tekstury skóry oraz jej ogólnej kondycji, sprawiając, że skóra jest nie tylko bardziej nawilżona, ale i zdrowsza, pełna blasku oraz wyraźnie odmłodzona.',
          },
        ],
      },
      {
        type: 'section',
        subtitle: 'Zabiegi z kwasem hialuronowym w IDEALE',
        nodes: [
          {
            type: 'paragraph',
            content:
              'Gabinet Medycyny Estetycznej IDEALE w Warszawie oferuje różnorodne zabiegi wykorzystujące kwas hialuronowy, które odpowiadają na specyficzne potrzeby naszych klientów. W naszej ofercie znajdują się:',
          },
          {
            type: 'node',
            content: (
              <TreatmentsList
                offers={treatmentsHubs.hialuronicAcid.treatments}
              />
            ),
          },
          {
            type: 'paragraph',
            content:
              'Wybór zabiegów z kwasem hialuronowym w Gabinecie Medycyny Estetycznej IDEALE odpowiada na potrzeby klientów, którzy poszukują skutecznych, bezpiecznych i minimalnie inwazyjnych metod odmładzania, poprawy wyglądu i  nawilżeniu skóry. Każdy z oferowanych przez nas zabiegów został starannie dobrany, aby zapewnić optymalne efekty przy jednoczesnym minimalizowaniu dyskomfortu i czasu rekonwalescencji. Stosując zaawansowane techniki i najwyższej jakości produkty, gwarantujemy, że każda terapia przynosi wymierne korzyści, zwiększając zadowolenie i pewność siebie naszych pacjentów.',
          },
          { type: 'node', content: <BannerMonthlyPromo /> },
        ],
      },
      {
        type: 'section',
        subtitle:
          'Wypełnianie zmarszczek kwasem hialuronowym tylko w IDEALE Warszawa!',
        nodes: [
          {
            type: 'paragraph',
            content:
              '<b>IDEALE Medycyna Estetyczna to gabinet stworzony przez kobietę dla kobiet</b>. Dążymy do tego aby nasi Pacjenci poczuli się piękni i dostrzegli w sobie wyjątkowe, unikatowe cechy. Podkreślamy to co interesujące. Słuchamy i doradzamy. Nasz doświadczony lekarz medycyny estetycznej ułoży dla Ciebie plan, indywidualnie dopasuje zabiegi mezoterapii igłowej, doradzi w temacie pielęgnacji skóry, dzięki czemu wyjdziesz od nas piękna, naturalna i promienna, a Twoja skóra będzie wyglądać na młodszą i bardziej wypoczętą. Nasza klinika mieści się w Warszawie na Woli, tuż przy stacji metra Młynów zapewniając łatwy dojazd naszym pacjentom. Wokół kliniki znajduje się również wiele miejsc parkingowych.',
          },
          {
            type: 'paragraph',
            content: `W IDEALE skupiamy się na dostarczaniu skutecznych i bezpiecznych <a href="${PATHS.OFFER}" >zabiegów medycyny estetycznej</a>, które czerpiemy prosto z międzynarodowych kongresów i szkoleń. Dążymy do zapewnienia najwyższej jakości usług, a nasz zespół stale poszerza swoją wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom. Cena wybranego zabiegu jest dostępna w naszym cenniku.`,
          },
          {
            type: 'paragraph',
            content: `Razem z nami odkryjesz potencjał swojej urody i swoją unikatowość. Poczujesz się zadbana i zrelaksowana. Zaufaj naszemu doświadczeniu, abyś mogła cieszyć się piękną, zadbaną i młodą skórą.`,
          },
        ],
      },
      {
        type: 'bannerSection',
      },
      {
        type: 'newsletter',
      },
    ],
  };
};
